exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parts-electronics-tsx": () => import("./../../../src/pages/parts/electronics.tsx" /* webpackChunkName: "component---src-pages-parts-electronics-tsx" */),
  "component---src-pages-parts-floatwheel-tsx": () => import("./../../../src/pages/parts/floatwheel.tsx" /* webpackChunkName: "component---src-pages-parts-floatwheel-tsx" */),
  "component---src-pages-parts-gt-tsx": () => import("./../../../src/pages/parts/gt.tsx" /* webpackChunkName: "component---src-pages-parts-gt-tsx" */),
  "component---src-pages-parts-index-tsx": () => import("./../../../src/pages/parts/index.tsx" /* webpackChunkName: "component---src-pages-parts-index-tsx" */),
  "component---src-pages-parts-misc-tsx": () => import("./../../../src/pages/parts/misc.tsx" /* webpackChunkName: "component---src-pages-parts-misc-tsx" */),
  "component---src-pages-parts-pint-tsx": () => import("./../../../src/pages/parts/pint.tsx" /* webpackChunkName: "component---src-pages-parts-pint-tsx" */),
  "component---src-pages-parts-xr-tsx": () => import("./../../../src/pages/parts/xr.tsx" /* webpackChunkName: "component---src-pages-parts-xr-tsx" */),
  "component---src-pages-parts-xrclassic-tsx": () => import("./../../../src/pages/parts/xrclassic.tsx" /* webpackChunkName: "component---src-pages-parts-xrclassic-tsx" */),
  "component---src-pages-resources-applications-tsx": () => import("./../../../src/pages/resources/applications.tsx" /* webpackChunkName: "component---src-pages-resources-applications-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-resources-repositories-tsx": () => import("./../../../src/pages/resources/repositories.tsx" /* webpackChunkName: "component---src-pages-resources-repositories-tsx" */),
  "component---src-pages-resources-spreadsheets-tsx": () => import("./../../../src/pages/resources/spreadsheets.tsx" /* webpackChunkName: "component---src-pages-resources-spreadsheets-tsx" */),
  "component---src-pages-resources-vendors-tsx": () => import("./../../../src/pages/resources/vendors.tsx" /* webpackChunkName: "component---src-pages-resources-vendors-tsx" */),
  "component---src-pages-resources-videoguides-tsx": () => import("./../../../src/pages/resources/videoguides.tsx" /* webpackChunkName: "component---src-pages-resources-videoguides-tsx" */),
  "component---src-pages-resources-websites-tsx": () => import("./../../../src/pages/resources/websites.tsx" /* webpackChunkName: "component---src-pages-resources-websites-tsx" */),
  "component---src-pages-resources-writtenguides-tsx": () => import("./../../../src/pages/resources/writtenguides.tsx" /* webpackChunkName: "component---src-pages-resources-writtenguides-tsx" */)
}

